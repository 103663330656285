import React, { Fragment } from "react"
import Layout from "../components/layout";
import { graphql } from "gatsby"
import PostItem from "../components/post";
import Ads from "../components/ads";
import Meta from "../components/meta";

export const Head = () => {
  return (
    <Meta />
  )
}

const IndexPage = ({ data }: any) => {

  const feature1 = data.feature1.nodes[0] || null
  const feature2 = data.feature2.nodes[0] || null
  const feature3 = data.feature3.nodes[0] || null

  return (
    <Layout>
      <Ads name="home_top" />
      <main className="py-24px">

        <div className="container">
          <div className="feature-box mb-24px">
            <div className="left">
              { feature1 &&
                <PostItem key={feature1.id} post={feature1} type="post" custom="feature-large" />
              }
            </div>
            <div className="right">
              { feature2 &&
                <PostItem key={feature2.id} post={feature2} type="post" custom="feature-small" />
              }
              { feature3 &&
                <PostItem key={feature3.id} post={feature3} type="post" custom="feature-small" />
              }
            </div>
          </div>
        </div>

        <div className="container narrow">
          <Ads name="home_inbody1" />
          <div className="py-24px">
            { data.latest.nodes.map((post: any, i: number) => (
              <Fragment key={post.id}>
                { (i!==0 && i%3==0) &&
                  <div className="mb-24px">
                    <Ads name="home_inbody2" />
                  </div>
                }
                <PostItem post={post} type="post" />
              </Fragment>
            )) }
          </div>
          <Ads name="home_inbody3" />
        </div>

      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    feature1: allStrapiPost(filter: { feature: {eq: "feature-1"} }, sort: { fields: [publishedAt], order: DESC }) {
      nodes {
        ...PostData
      }
    }
    feature2: allStrapiPost(filter: { feature: {eq: "feature-2"} }, sort: { fields: [publishedAt], order: DESC }) {
      nodes {
        ...PostData
      }
    }
    feature3: allStrapiPost(filter: { feature: {eq: "feature-3"} }, sort: { fields: [publishedAt], order: DESC }) {
      nodes {
        ...PostData
      }
    }
    latest: allStrapiPost(filter: { feature: {eq: null} }, sort: { fields: [publishedAt], order: DESC }) {
      nodes {
        ...PostData
      }
    }
  }
`

export default IndexPage